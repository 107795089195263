


































import { Vue, Component } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
@Component({
  name: "LoginContainer",
})
export default class LoginContainer extends Vue {
  formLogin = {
    userId: "",
    password: "",
  };

  async submit() {
    const result = await UserModule.login(this.formLogin);
    if (result) {
      const { redirect = "/" } = this.$route.query;
      this.$router.push(redirect as string);
    }
  }
}
